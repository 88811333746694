import 'bootstrap';
import 'bootstrap-select/js/bootstrap-select';
import 'bootstrap-select/js/i18n/defaults-ru_RU';
import 'jquery-slimscroll';
import 'parsleyjs';

global.$      = require('jquery');
global.jQuery = require('jquery');

require('./lib/datatables/datatables');

$(() => {
    $('a.history-back').on('click', function (e) {
        if (document.referrer) {
            document.location.href = document.referrer;
            return false;
        }
    });

    // Чекбоксы
    (function () {
        let
            $checkboxes   = $('input[type="checkbox"][data-group]'),
            lastChecked = null;

        /**
         * Выбор чекбоксов через shift
         */
        $checkboxes.on('click', function(e) {
            if (!lastChecked) {
                lastChecked = this;
                return;
            }

            if (e.shiftKey) {
                const
                    start = $checkboxes.index(this),
                    end   = $checkboxes.index(lastChecked);

                $checkboxes.slice(
                    Math.min(start, end),
                    Math.max(start, end) + 1
                ).prop('checked', lastChecked.checked);
            }

            lastChecked = this;
        });

        /**
         * Первоначальное состояние общего чекпоинта
         */
        $('input[type="checkbox"][data-checkbox-group]').each(function () {
            const group = $(this).data('checkboxGroup');

            $(this).prop('checked', (
                !$('input[type="checkbox"][data-group="'+ group +'"]:not(:checked)').length &&
                $('input[type="checkbox"][data-group="'+ group +'"]').length
            ));
        });

        /**
         * Изменение состояния общего чекбокса при изменении одного из элемен
         */
        $('input[type="checkbox"][data-group]').on('change', function () {
            const group = $(this).data('group');

            $('input[type="checkbox"][data-checkbox-group="'+ group +'"]').prop('checked', !$('input[type="checkbox"][data-group="'+ group +'"]:not(:checked)').length);
        });

        /**
         * Выбрать все чекбоксы в группе
         */
        $('input[type="checkbox"][data-checkbox-group]').on('change', function () {
            const
                group   = $(this).data('checkboxGroup'),
                checked = $(this).prop('checked');

            $('input[type="checkbox"][data-group="'+ group +'"]').prop('checked', checked);
        });

        /**
         * readonly для чекбоксов
         */
        $(':checkbox[readonly]').click(function() {
            return false;
        });
    }());

    // Активация Slim Scroll
    $('.slim-scroll').each(function() {
        const $this = $(this);
        let options = {
            width: $this.data('ssWidth'),
            height: $this.data('ssHeight'),
            size: $this.data('ssSize'),
            position: $this.data('ssPosition'),
            color: $this.data('ssColor'),
            alwaysVisible: $this.data('ssAalwaysVisible'),
            distance: $this.data('ssDistance'),
            start: $this.data('ssStart'),
            railVisible: $this.data('ssRailVisible'),
            railColor: $this.data('ssRailColor'),
            railOpacity: $this.data('ssRailOpacity'),
            wheelStep: $this.data('ssWheelStep'),
            allowPageScroll: $this.data('ssAllowPageScroll'),
            disableFadeOut: $this.data('ssDisableFadeOut'),
        };
    
        for (var propName in options) {
            if (options[propName] === null || options[propName] === undefined) {
                delete options[propName];
            }
        }
    
        $(this).slimScroll(options);
    });
    
    // Навигация по странице с прокруткой
    if ($('.sidebar-scroll-nav a').length) {
        $('.sidebar-scroll-nav a').on('click', function(event) {
            // Ссылки на странице
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
            ) {
                // Получить элемент для перехода
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    
                // Если цель существует
                if (target.length) {
                    event.preventDefault();
                    
                    $('html, body').animate({
                        scrollTop: target.offset().top - 90
                    }, 1000, function() {
                        const $target = $(target);
    
                        $target.focus();
    
                        if ($target.is(":focus")) { // Проверка фокусировки цели
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Добавление tabindex для не фокусируемых элементов
                            $target.focus(); // Повторная установка фокуса
                        }
                    });
                }
            }
    
            $('.sidebar-scroll-nav a').each(function() {
                $(this).removeClass('active');
            });
    
            $(this).addClass('active');
        });
    }
    
    if ($('[data-toggle="tooltip"]').length) {
        $('[data-toggle="tooltip"]').tooltip();
    }
    
    if ($('[data-toggle="popover"]').length) {
        $('[data-toggle="popover"]').popover();
    }

    $('.add-another-collection-widget').click(function (e) {
        const list = $($(this).attr('data-list-selector'));
        let counter = list.data('widget-counter') || list.children().length;
        let newWidget = list.attr('data-prototype');

        newWidget = newWidget.replace(/__name__/g, counter);
        counter++;
        list.data('widget-counter', counter);

        const newElem = $(list.attr('data-widget-tags')).html(newWidget);
        newElem.appendTo(list);
    });
});