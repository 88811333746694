import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-colreorder-bs4';
import 'datatables.net-fixedcolumns-bs4';
import 'datatables.net-fixedheader-bs4';
import 'datatables.net-select-bs4';

const language = require('./ru.json');

$.extend(true, $.fn.dataTable.defaults, {
    language:     language,
    pagingType:   "full_numbers",
    colReorder:   true,
    //fixedColumns: true,
    fixedHeader: true,
    //lengthChange: false,
    //buttons: ['colvis'],
    dom:
        "<'row pt-2 pb-1'<'col-xs-6 col-sm-5 col-lg-4 d-flex justify-content-start align-items-center'B><'col-xs-6 col-sm-3 col-lg-4 d-flex justify-content-center align-items-center'i><'col-xs-12 col-sm-4 d-flex justify-content-end align-items-center'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-6'l><'col-sm-6'p>>",
});

$.extend(true, $.fn.dataTable.Buttons.defaults, {
    dom: {
        container: { className: 'dt-buttons btn-group flex-wrap btn-group-sm' },
        button: { className: 'btn' },
    },
});

// Скрытие фиксированной шапки при переходе на другую вкладку
// https://datatables.net/forums/discussion/50988/fixedheader-in-boostrap-tabs-appears-broken
$('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
    $($.fn.dataTable.tables()).DataTable().fixedHeader.adjust();
});

/**
 * @param {JQuery<HTMLTableElement>} $table
 * @param {Object} options
 */
export function initDataTable($table, options = {}) {
    const $chbSelectAll = $table.find('thead th.select-all');

    options.columnDefs = options.columnDefs ?? [];

    if ($chbSelectAll.length) {
        options.select     = {
            style:    'multi',
            selector: 'td:first-child'
        };

        options.columnDefs.push({
            orderable: false,
            className: 'select-checkbox',
            targets:   0,
        });
    }

    if ($table.hasClass('db-item-list')) {
        const dbButtons = [
            createNewItemBtn($table),
            createMultipleDeleteItemsBtn($table),
            createMultipleArchiveItemsBtn($table),
        ];
        
        options.buttons = dbButtons.concat(options.buttons ?? []);
    }

    const dataTable = $table.DataTable(options);

    if ($chbSelectAll.length) {
        // Выбор/сброс всех строк на странице
        $chbSelectAll.on('click', function() {
            const rows = dataTable.rows({page: 'current'});

            if ($chbSelectAll.hasClass('selected')) {
                rows.deselect();
                $chbSelectAll.removeClass('selected');
            } else {
                rows.select();
                $chbSelectAll.addClass('selected');
            }
        });

        // Сброс выбранных строк при отрисовке
        dataTable.on('draw', () => {
            dataTable.rows().deselect();
            $chbSelectAll.removeClass('selected');
        });
    }

    return dataTable;
}

/**
 * @param {JQuery<HTMLTableElement>} $table
 */
function createNewItemBtn($table) {
    const url = $table.data('newItemUrl');

    if (!url) return;

    return {
        text:      'Добавить',
        className: 'btn-primary',
        action:    (e, dt, $btn, config) => location.href = url,
    };
}

/**
 * @param {JQuery<HTMLTableElement>} $table
 */
function createMultipleDeleteItemsBtn($table) {
    const url   = $table.data('multipleDeleteItemsUrl');
    const token = $table.data('multipleDeleteItemsToken');

    if (!url || !token) return;

    const $form = $(`
        <form
            method="post"
            action="${url}"
            onsubmit="return confirm('Вы уверены, что хотите удалить выбранные элементы?');"
        >
            <input type="hidden" name="_method" value="DELETE">
            <input type="hidden" name="_token" value="${token}">
        </form>
    `);

    return {
        text:      'Удалить',
        className: 'btn-danger',
        action:    (e, dt, $btn, config) => {
            const $rows = dt.rows({
                selected: true,
                page:     'current'
            }).nodes().to$();

            if (!$rows.length) return;

            $rows.each((index, row) => {
                const itemId = $(row).data('itemId');
                $form.append($(`<input type="hidden" name="ids[]" value="${itemId}">`));
            });

            $form.trigger('submit');
        },
    };
}

/**
 * @param {JQuery<HTMLTableElement>} $table
 */
function createMultipleArchiveItemsBtn($table) {
    const url   = $table.data('multipleArchivingItemsUrl');
    const token = $table.data('multipleArchivingItemsToken');

    if (!url || !token) return;

    const $form = $(`
        <form
            method="post"
            action="${url}"
            onsubmit="return confirm('Вы уверены, что хотите архивировать выбранные элементы?');"
        >
            <input type="hidden" name="_method" value="POST">
            <input type="hidden" name="_token" value="${token}">
        </form>
    `);

    return {
        text:      'В архив',
        className: 'btn-warning',
        action:    (e, dt, $btn, config) => {
            const $rows = dt.rows({
                selected: true,
                page:     'current'
            }).nodes().to$();

            if (!$rows.length) return;

            $rows.each((index, row) => {
                const itemId = $(row).data('itemId');
                $form.append($(`<input type="hidden" name="ids[]" value="${itemId}">`));
            });

            $form.trigger('submit');
        },
    };
}
